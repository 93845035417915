import { Button, Link, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../i18n/const";

export default function ({

}) {
    const { t } = useTranslation(TranslatorNS.SETTINGS);

    return (
        <>
        <Tooltip title={t("topbar.supportArticles")}>
            <Button
                color="inherit"
                component={Link}
                target="_blank"
                href="https://help.zenfit.io/"
            >
                {t("topbar.helpTooltip")}
            </Button>

        </Tooltip>
        </>
    );
}
